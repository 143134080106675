@use './css-variables';
@use './typography' as *;
@use './responsive' as *;

@mixin services-grid($amount, $counter) {
    
    $block: '.services-grid';
    #{$block} {
        &-list {
            --amount: #{$amount};
            --counter: #{$counter};

            position: relative;
            padding: 0;
            margin: 0;
            list-style-type: none;
            display: grid;
            grid-template-columns: repeat(var(--amount), 1fr 2fr) 1fr;
            //border: 1px solid red;

            @include for-phone-only {
                grid-gap: 1.5rem 3rem;
            }

            @include for-tablet-portrait-up {
                grid-gap: 2rem 4rem;
            }

            // @include for-tablet-landscape-up {
            //     grid-gap: 3rem 6rem;
            // }
        }

        &-item {
            position: relative;
            grid-column: 1 / span 3;
            //grid-row: calc(var(--counter) + var(--counter)) / span 2;
            padding-bottom: 90%;
            //border: 1px solid green;
        }

        &-content {
            position: absolute;
            height: 100%;
            width: 100%;
            color: var(--clr-bg-1);
            background-color: var(--clr-fg-1);
            clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                text-align: center;
                @include ff-playfair-display-normal-400;
                
                @include for-phone-only {
                    @include fs-body-small-2;
                }
    
                @include for-tablet-portrait-up {
                    @include fs-body-small-1;
                }
            }
        }
        
        &-heading {
            p {
                text-align: center;
                @include ff-playfair-display-normal-400;

                @include for-phone-only {
                    @include fs-body-small-1;
                }
    
                @include for-tablet-portrait-up {
                    @include fs-body;
                }
            }
        }
    }
}

@mixin services-grid-item($amount, $counter) {
    --amount: #{$amount};
    --counter: #{$counter};

    @for $i from 1 through $amount {
        &:nth-of-type(#{$amount}n + #{$i}) {
            grid-column: #{$i + $i - 1} / span 3;
            @if $i % 2 == 0 {
                grid-row: calc(var(--counter) + var(--counter) - 1) / span 2;
            }
        }
    }

    @for $i from 1 through 2 {
        &:nth-of-type(n + #{$i * $amount + 1}) {
            --counter: #{$i + 1};
        }
    }
}

.services-container {
    position: relative;
    overflow: hidden;

    .content-container { 
        padding-top: 5rem;
        
        .services-content-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 32px;

            @include for-phone-only {
                flex-direction: column;
            }

            @include for-tablet-portrait-up {
                flex-direction: column;
            }

            @include for-tablet-landscape-up {
                flex-direction: row;
            }

            .services-info-container {
                flex: 1;
                padding: 0 16px;

                a {
                    color: var(--clr-utility-green-1);
                    border-bottom: 1px solid var(--clr-utility-green-1);
                    padding-bottom: 1px;
                }
            }

            .services-grid-container {
                flex: 1;
                //background-color: red;
                margin: auto;

                display: flex;
                justify-content: center;
                align-items: center;

                @include services-grid(3, 1); 

                .services-grid-item {
                    grid-row: calc(var(--counter) + var(--counter)) / span 2;
                    @include services-grid-item(3, 1);   
                }

                .services-grid-heading {
                    display: block;
                    & > * {
                        background-color: var(--clr-bg-1) !important;
                        color: var(--clr-fg-1) !important;
                    }
                }
            }
        }
    }

}