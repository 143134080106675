@use './css-variables';
@use './typography' as *;
@use './responsive' as *;

.contact-container {
    position: relative;
    //overflow: hidden;

    width: 100%;
    height: 100vh;
    //border: 1px solid green;

    .contact-canvas {
        position: absolute;
        //border: 1px solid red;

        width: 100%;
        height: 100%;
        
        @include for-phone-only {
            display: none;
        }
    }

    .content-container { 
        width: 100%;
        height: 100%;
        padding-top: 5rem;

        .contact-content-container {
            width: 100%;

            .contact-info {
                position: absolute;
                width: 100%;

                @include for-phone-only {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    gap: 32px;
                }

                @include for-tablet-portrait-up {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: space-between;
                    bottom: 8vh;
                    padding: 16px;
                }

                .firm-location, .contact-address {
                    h6 {
                        @include fs-body-small-1;
                        margin-bottom: 8px;
                    }
                }
            }
            
            .contact-footer {
                padding: 8px 0;
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 7vh;
                border-top: 0.5px solid var(--clr-fg-1);
                
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                p {
                    @include fs-body-small-1;
                }
            }

            // display: flex;
            // flex-direction: column;
            // align-items: flex-end;
            // justify-content: flex-start;

            

            // .contact-info {
            //     background-color: red;
            //     width: 100%;

            //     @include for-phone-only {
            //         display: flex;
            //         flex-direction: column;
            //         align-items: flex-start;
            //         justify-content: center;

            //         .firm-location, .contact-address {
            //             margin: 24px auto;
            //             text-align: center;

            //             h6 {
            //                 @include fs-body-small-1;
            //                 margin-bottom: 8px;
            //             }
            //         }

            //     }

            //     @include for-tablet-portrait-up {
            //         position: absolute;
            //         bottom: 20vh;

            //         display: flex;
            //         flex-direction: row;
            //         align-items: flex-start;
            //         justify-content: space-between;

            //         h6 {
            //             @include fs-body-small-1;
            //             margin-bottom: 8px;
            //         }
            //     }
            // }

            // .contact-footer {
            //     position: absolute;
            //     bottom: 0;
            //     text-align: center;
            //     width: 100%;
            //     height: 10vh;
            //     background-color: var(--clr-bg-1);
            //     color: var(--clr-fg-1);
            //     z-index: var(--z-index-2);
            //     border-top: 0.5px solid var(--clr-fg-1);

            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            // }

        }
    }

}