@use './responsive' as *;

@font-face {
    font-display: swap;
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/playfair-display-v37-latin/playfair-display-v37-latin-regular.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/oswald-v53-latin/oswald-v53-latin-regular.woff2') format('woff2');
}



@mixin ff-playfair-display-normal-400 {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
}

@mixin ff-oswald-normal-400 {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
}


/* body fonts - minor third, base font-16px */

@mixin fs-body {
    font-size: 1rem;
}

@mixin fs-body-small-1 {
    font-size: 0.833rem;
}

@mixin fs-body-small-2 {
    font-size: 0.694rem;
}


/* heading fonts - minor third, base font-16px */

@mixin fs-heading-h1 {
    font-size: 2.986rem;
}

@mixin fs-heading-h2 {
    font-size: 2.488rem;
}

@mixin fs-heading-h3 {
    font-size: 2.074rem;
}

@mixin fs-heading-h4 {
    font-size: 1.728rem;
}

@mixin fs-heading-h5 {
    font-size: 1.44rem;
}

@mixin fs-heading-h6 {
    font-size: 1.2rem;
}


/* banner fonts - golden ratio, base font-16px */

@mixin fs-banner-h1 {
    font-size: 17.942rem;
}

@mixin fs-banner-h2 {
    font-size: 11.089rem;
}

@mixin fs-banner-h3 {
    font-size: 6.854rem;
}

@mixin fs-banner-h4 {
    font-size: 4.236rem;
}

@mixin fs-banner-h5 {
    font-size: 2.618rem;
}

@mixin fs-banner-h6 {
    font-size: 1.618rem;
}

a {
    @include ff-playfair-display-normal-400;
}

p {
    @include ff-playfair-display-normal-400;
    @include fs-body;
}

h1 {
    @include ff-playfair-display-normal-400;

    @include for-phone-only {
        @include fs-heading-h1;
    }

    @include for-tablet-portrait-up {
        @include fs-banner-h3;
    }

    @include for-tablet-landscape-up {
        @include fs-banner-h3;
    }

    @include for-desktop-up {
        @include fs-banner-h2;
    }
}

h2 {
    @include ff-playfair-display-normal-400;

    @include for-phone-only {
        @include fs-heading-h3;
    }

    @include for-tablet-portrait-up {
        @include fs-heading-h2;
    }

    @include for-tablet-landscape-up {
        @include fs-banner-h5;
    }

    @include for-desktop-up {
        @include fs-banner-h4;
    }
}

h3 {
    @include ff-playfair-display-normal-400;

    @include for-phone-only {
        @include fs-heading-h4;
    }

    @include for-tablet-portrait-up {
        @include fs-heading-h3;
    }
}

h4 {
    @include ff-playfair-display-normal-400;

    @include for-phone-only {
        @include fs-heading-h5;
    }

    @include for-tablet-portrait-up {
        @include fs-heading-h4;
    }
}

h5 {
    @include ff-playfair-display-normal-400;

    @include for-phone-only {
        @include fs-heading-h6;
    }

    @include for-tablet-portrait-up {
        @include fs-heading-h5;
    }
}

h6 {
    @include ff-playfair-display-normal-400;
    
    @include for-phone-only {
        @include fs-body;
    }

    @include for-tablet-portrait-up {
        @include fs-heading-h6;
    }
}
