@use './css-variables';
@use './typography' as *;
@use './responsive' as *;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    width: 100%;
    height: auto;
    max-width: var(--max-width);
    margin: 0 auto;
    //border: 1px solid green;

    overscroll-behavior: none;
   
    background-color: var(--clr-bg-1);
    color: var(--clr-fg-1);

    @include ff-playfair-display-normal-400;

    position: relative;
}
