@use './css-variables';
@use './typography' as *;
@use './responsive' as *;

.header-container {
    height: 5rem;
    width: 100%;
    background-color: var(--clr-fg-1);
    z-index: var(--z-index-999);

    position: sticky;
    top: 0;

    nav {
        height: 100%;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .wk-branding-logo {
            background-color: transparent;
            width: max-content;

            svg {
                g {
                    fill: var(--clr-bg-1) !important;
                }
            }
        }

        .nav-btn {
            width: 32px;
            height: 32px;
            cursor: pointer;
            //border: 1px solid green;
            margin-right: 16px;
    
            display: flex;
            justify-content: center;
            align-items: center;
    
            transition: all 1s ease-in-out;
    
            .hamburger {
                width: 32px;
                height: 3px;
                background: var(--clr-bg-1);
                border-radius: 5px;
                transition: all 1s ease-in-out;
    
                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    width: 32px;
                    height: 3px;
                    background: var(--clr-bg-1);
                    border-radius: 3px;
                    transition: all .5s ease-in-out;
                }
                &::before {
                    transform: translateY(-8px);
                }
                &::after {
                    transform: translateY(8px);
                }
            }
            
            &.open {
                .hamburger {
                    background-color: transparent;
    
                    &::before {
                        transform: rotate(45deg);
                    }
                    &::after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }       
    }
}