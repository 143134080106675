@use './css-variables';
@use './typography' as *;
@use './responsive' as *;

.home-container {
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .scroll-info-canvas-home {
        position: absolute;
        //border: 1px solid red;
        right: 0;
        bottom: 12%;
    }

    .content-container {
        margin-top: -20vh;
        .section-title {
            display: none;
        }
    
        .hero-container {
            text-align: center;
            h1 {
                overflow: hidden;
                span {
                    display: inline-block;
                    transform: translateY(100%);
                    transition: .8s;
                    transition-timing-function: ease;
                    //border: 1px solid red;

                    &:last-child {
                        transform: translateY(500%);
                        transition: .8s;
                        transition-timing-function: ease;
                        vertical-align: top;

                        @include for-phone-only {
                            font-size: 1.5rem !important;
                            padding-top: 0.5rem;
                        }
                        @include for-tablet-portrait-up {
                            font-size: 3rem !important;
                            padding-top: 1.5rem;
                        }
                        @include for-tablet-landscape-up {
                            font-size: 3rem !important;
                            padding-top: 1.5rem;
                        }
                        @include for-desktop-up {
                            font-size: 4rem !important;
                            padding-top: 2.5rem;
                        }
                    }

                    
                }
            }
    
            h4, h6 {
                opacity: 0;
                animation: anim-headings .8s ease .8s forwards;
            }
    
            @keyframes anim-headings {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
    
            @include for-phone-only {
                h4, h6 {
                    margin: 16px 0;
                }
            }
    
            @include for-tablet-portrait-up {
                h6 {
                    margin: 16px 0;
                }
            }
    
        }
    
        .cta-container {
            text-align: center;
            margin: 32px 0;
    
            .cta-btn {
                text-decoration: none;
                opacity: 0;
                animation: anim-show .8s ease 1.2s forwards;
            }
    
            @keyframes anim-show {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
        }
    }
    
}