@use './css-variables';
@use './typography' as *;
@use './responsive' as *;

.products-container {
    position: relative;
    width: 100%;
    height: 600vh;

    .content-container { 
        width: 100%;
        height: 100%;
        padding-top: 5rem;

        .products-info-container {
            position: sticky;
            top: 12vh;
            overflow: hidden;

            width: 100%;
            height: 100vh;
            
            .products-info {
                .mt-16 {
                    margin-top: 16px;
                }
    
                p {
                    padding: 0 16px;
                }
            }

            .word-cloud {
                background-image: url('../assets/images/wordcloud.png');
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                
                @include for-phone-only {
                    height: 25%;
                }

                @include for-tablet-portrait-up {
                    height: 55%;
                }
            }

        }

        .product {
            position: sticky;
            top: 25vh;
            overflow: hidden;

            width: 100%;
            height: 100vh;

            .product-card {
                margin: auto;
    
                @include for-phone-only {
                    width: 80%;
                    height: 70%;
                }
    
                @include for-tablet-portrait-up {
                    width: 70%;
                    height: 60%;
                }

                @include for-tablet-landscape-up {
                    width: 65%;
                    height: 50%;
                }
        
                border: 1px solid var(--shade-1);
                background-color: var(--clr-bg-1);
                border-radius: 5px;
        
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                flex-wrap: wrap;
                
                padding: 8px;
        
                .row {
                    text-align: center;
                    width: 100%;
        
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
        
                    h5 {
                        position: relative;
        
                        &::after {
                            content: '';
                            width: 30%;
                            display: block;
                            height: 3px;
                            background-color: var(--clr-fg-1);
                            margin: auto;
                            margin-top: 10px; 
                        }
                    }
        
                    p {
                        padding: 4px 0;
                        @include for-phone-only {
                            @include fs-body-small-1;
                        }
                        @include for-tablet-portrait-up {
                            @include fs-body-small-1;
                        }
                        @include for-tablet-landscape-up {
                            @include fs-body;
                        }
                    }
                    
                    &:nth-child(odd) {
                        //background-color: var(--clr-bg-2);
                        padding: 8px 0;
                    }
        
                    &:nth-child(even) {
                        //background-color: var(--clr-utility-F0EBE3);
                        padding: 8px 0;
                    }
        
                    &:nth-child(1) {
                        //background-color: var(--clr-bg-2);
                        padding: 16px 0 !important;
                    }
                }
            }
        }
    }

}

// .products-container {
//     position: relative;
//     //overflow: hidden;
    
//     width: 100%;
//     height: 500vh;

//     .content-container { 
//         .products-content-container {

//             .products-info-container {
//                 width: 100%;
//                 height: 70vh;

//                 position: sticky;
//                 //overflow: hidden;
//                 top: 0;

//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 gap: 16px;

//                 @include for-phone-only {
//                     flex-direction: column;
//                 }
                
//                 @include for-tablet-portrait-up {
//                     flex-direction: row;
//                 }

//                 .products-info {

//                     p {
//                         //@include fs-heading-h6;
//                     }
//                     .mt-16 {
//                         margin-top: 16px;
//                     }
//                 }

//                 .word-cloud {
//                     width: 100%;
//                     height: 100%;
//                     background-image: url('../assets/images/wordcloud.png');
//                     background-position: center;
//                     background-size: cover;
//                     background-repeat: no-repeat;
//                 }
//             }

//             .product {}
//         }
//     }

// }