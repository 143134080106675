@use './css-variables';
@use './typography' as *;
@use './responsive' as *;


.overflow-x-clip {
    overflow-x: clip;
}

.overflow-y-clip {
    overflow-y: clip !important;
}

a {
    text-decoration: none;
}

.clr-utility-green-1 {
    color: var(--clr-utility-green-1);
}

.clr-utility-red-1 {
    color: var(--clr-utility-red-1);
}

.mt-8 {
    margin-top: 8px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-32 {
    margin-top: 32px;
}

.btn {
    padding: 4px 8px;
    border: 1px solid var(--shade-1);
    border-radius: 5px;
    color: var(--clr-fg-1);
    cursor: pointer;
    display: inline-block;

    @include for-phone-only {
        width: 120px;
        @include fs-body-small-1;
    }
    @include for-tablet-portrait-up {
        width: 150px;
        @include fs-body;
    }

    &:hover {
        border: 1px solid var(--clr-fg-1);
        background: var(--clr-fg-1);
        color: var(--clr-bg-1);
    }

    &:active {
        border: 1px solid var(--clr-fg-1);
        background: var(--clr-fg-1);
        color: var(--clr-bg-1);
    }
}

.section-title {
    height: 5rem;
    margin: 16px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    .brush-stroke {
        background-image: url(../assets/images/brush-stroke.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 300px;
        padding: 16px 0;
        
        h3 {
            color: #FFFFFF;
            text-align: center;
        }
    }

}