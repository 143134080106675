:root {
  --max-width: 1240px;
  --clr-bg-1: #fff;
  --clr-fg-1: #222;
  --clr-utility-red-1: red;
  --clr-utility-green-1: #228b22;
  --z-index-1000: 1000;
  --z-index-999: 999;
  --z-index-998: 998;
  --z-index-3: 3;
  --z-index-2: 2;
  --z-index-1: 1;
  --shade-1: #a6a6a6;
  --profile-card-gradient-color: linear-gradient(180deg, #333, #222);
  --profile-card-border-color: #222;
}

@font-face {
  font-display: swap;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  src: url("playfair-display-v37-latin-regular.48dde17b.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Oswald;
  font-style: normal;
  font-weight: 400;
  src: url("oswald-v53-latin-regular.253a1902.woff2") format("woff2");
}

a {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
}

p {
  font-family: Playfair Display;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}

h1 {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
}

@media (width <= 599px) {
  h1 {
    font-size: 2.986rem;
  }
}

@media (width >= 600px) {
  h1 {
    font-size: 6.854rem;
  }
}

@media (width >= 900px) {
  h1 {
    font-size: 6.854rem;
  }
}

@media (width >= 1200px) {
  h1 {
    font-size: 11.089rem;
  }
}

h2 {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
}

@media (width <= 599px) {
  h2 {
    font-size: 2.074rem;
  }
}

@media (width >= 600px) {
  h2 {
    font-size: 2.488rem;
  }
}

@media (width >= 900px) {
  h2 {
    font-size: 2.618rem;
  }
}

@media (width >= 1200px) {
  h2 {
    font-size: 4.236rem;
  }
}

h3 {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
}

@media (width <= 599px) {
  h3 {
    font-size: 1.728rem;
  }
}

@media (width >= 600px) {
  h3 {
    font-size: 2.074rem;
  }
}

h4 {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
}

@media (width <= 599px) {
  h4 {
    font-size: 1.44rem;
  }
}

@media (width >= 600px) {
  h4 {
    font-size: 1.728rem;
  }
}

h5 {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
}

@media (width <= 599px) {
  h5 {
    font-size: 1.2rem;
  }
}

@media (width >= 600px) {
  h5 {
    font-size: 1.44rem;
  }
}

h6 {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
}

@media (width <= 599px) {
  h6 {
    font-size: 1rem;
  }
}

@media (width >= 600px) {
  h6 {
    font-size: 1.2rem;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  max-width: var(--max-width);
  overscroll-behavior: none;
  background-color: #fff;
  background-color: var(--clr-bg-1);
  color: var(--clr-fg-1);
  width: 100%;
  max-width: 1240px;
  height: auto;
  margin: 0 auto;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  position: relative;
}

.overflow-x-clip {
  overflow-x: clip;
}

.overflow-y-clip {
  overflow-y: clip !important;
}

a {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.clr-utility-green-1 {
  color: var(--clr-utility-green-1);
}

.clr-utility-red-1 {
  color: var(--clr-utility-red-1);
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.btn {
  border: 1px solid #a6a6a6;
  border: 1px solid var(--shade-1);
  color: var(--clr-fg-1);
  cursor: pointer;
  border-radius: 5px;
  padding: 4px 8px;
  display: inline-block;
}

@media (width <= 599px) {
  .btn {
    width: 120px;
    font-size: .833rem;
  }
}

@media (width >= 600px) {
  .btn {
    width: 150px;
    font-size: 1rem;
  }
}

.btn:hover, .btn:active {
  border: 1px solid #222;
  border: 1px solid var(--clr-fg-1);
  background: #222;
  background: var(--clr-fg-1);
  color: var(--clr-bg-1);
}

.section-title {
  justify-content: center;
  align-items: center;
  height: 5rem;
  margin: 16px 0;
  display: flex;
}

.section-title .brush-stroke {
  background-image: url("brush-stroke.26085a2f.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 300px;
  padding: 16px 0;
}

.section-title .brush-stroke h3 {
  color: #fff;
  text-align: center;
}

.preloader-container {
  background-color: #222;
  background-color: var(--clr-fg-1);
  z-index: 1000;
  z-index: var(--z-index-1000);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.preloader-container .loader {
  text-align: center;
  width: 100%;
  height: 15px;
  position: fixed;
}

.preloader-container .loader .dot {
  width: 15px;
  height: 15px;
  margin: 0 2px;
  display: inline-block;
  position: relative;
}

.preloader-container .loader .dot:first-child:before, .preloader-container .loader .dot:first-child:after {
  animation-delay: 0s;
}

.preloader-container .loader .dot:last-child:before, .preloader-container .loader .dot:last-child:after {
  animation-delay: .2s;
}

.preloader-container .loader .dot:before {
  content: "";
  background: #fff;
  background: var(--clr-bg-1);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation-name: animDot;
  animation-duration: .9s;
  animation-timing-function: cubic-bezier(.75, 0, .2, 1);
  animation-iteration-count: infinite;
  animation-delay: .1s;
  position: absolute;
  left: 0;
}

.preloader-container .loader .dot:after {
  content: "";
  z-index: -1;
  background: #fff;
  background: var(--clr-bg-1);
  box-shadow: 0 0 1px #fff;
  box-shadow: 0px 0px 1px var(--clr-bg-1);
  opacity: .2;
  border-radius: 50%;
  width: 100%;
  height: 3px;
  animation-name: animDotShadow;
  animation-duration: .9s;
  animation-timing-function: cubic-bezier(.75, 0, .2, 1);
  animation-iteration-count: infinite;
  animation-delay: .1s;
  position: absolute;
  bottom: -2px;
  left: 0;
}

@keyframes animDot {
  0% {
    top: 0;
  }

  50% {
    top: -50px;
    transform: scale(1.1);
  }

  100% {
    top: 0;
  }
}

@keyframes animDotShadow {
  0% {
    transform: scaleX(1);
  }

  50% {
    opacity: 0;
    transform: scaleX(.6);
  }

  100% {
    transform: scaleX(1);
  }
}

main section {
  width: 100%;
  min-height: 100vh;
}

main section:first-child {
  padding-top: 0 !important;
}

.header-container {
  background-color: #222;
  background-color: var(--clr-fg-1);
  z-index: 999;
  z-index: var(--z-index-999);
  width: 100%;
  height: 5rem;
  position: sticky;
  top: 0;
}

.header-container nav {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.header-container nav .wk-branding-logo {
  background-color: #0000;
  width: max-content;
}

.header-container nav .wk-branding-logo svg g {
  fill: var(--clr-bg-1) !important;
}

.header-container nav .nav-btn {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  transition: all 1s ease-in-out;
  display: flex;
}

.header-container nav .nav-btn .hamburger {
  background: #fff;
  background: var(--clr-bg-1);
  border-radius: 5px;
  width: 32px;
  height: 3px;
  transition: all 1s ease-in-out;
}

.header-container nav .nav-btn .hamburger:before, .header-container nav .nav-btn .hamburger:after {
  content: "";
  background: #fff;
  background: var(--clr-bg-1);
  border-radius: 3px;
  width: 32px;
  height: 3px;
  transition: all .5s ease-in-out;
  position: absolute;
}

.header-container nav .nav-btn .hamburger:before {
  transform: translateY(-8px);
}

.header-container nav .nav-btn .hamburger:after {
  transform: translateY(8px);
}

.header-container nav .nav-btn.open .hamburger {
  background-color: #0000;
}

.header-container nav .nav-btn.open .hamburger:before {
  transform: rotate(45deg);
}

.header-container nav .nav-btn.open .hamburger:after {
  transform: rotate(-45deg);
}

.nav-menu-container {
  background-color: #222;
  background-color: var(--clr-fg-1);
  z-index: 998;
  z-index: var(--z-index-998);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 99%;
  transition: all 1s;
  display: flex;
  position: absolute;
  transform: translateX(-100vw);
}

@media (width <= 599px) {
  .nav-menu-container {
    width: 100%;
  }
}

@media (width >= 600px) {
  .nav-menu-container {
    width: 50%;
  }
}

.nav-menu-container ul {
  text-align: left;
  width: 100%;
  padding: 0 32px;
  position: sticky;
  top: 30%;
}

.nav-menu-container ul li {
  padding: 4px 0;
  list-style: none;
}

.nav-menu-container ul li a {
  color: var(--clr-bg-1);
  text-align: center;
  font-size: 2.074rem;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.nav-menu-container ul li a:hover {
  color: var(--shade-1);
}

.nav-menu-container .contact-info {
  background-color: #a6a6a6;
  background-color: var(--shade-1);
  width: 100%;
  padding: 8px 16px;
  position: sticky;
  top: 85%;
}

.nav-menu-container .contact-info p, .nav-menu-container .contact-info h6 {
  color: var(--clr-fg-1);
  text-align: right;
  padding: 0 16px;
}

.nav-menu-container .contact-info p {
  font-size: .833rem;
}

.home-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-container .scroll-info-canvas-home {
  position: absolute;
  bottom: 12%;
  right: 0;
}

.home-container .content-container {
  margin-top: -20vh;
}

.home-container .content-container .section-title {
  display: none;
}

.home-container .content-container .hero-container {
  text-align: center;
}

.home-container .content-container .hero-container h1 {
  overflow: hidden;
}

.home-container .content-container .hero-container h1 span {
  transition: all .8s;
  display: inline-block;
  transform: translateY(100%);
}

.home-container .content-container .hero-container h1 span:last-child {
  vertical-align: top;
  transition: all .8s;
  transform: translateY(500%);
}

@media (width <= 599px) {
  .home-container .content-container .hero-container h1 span:last-child {
    padding-top: .5rem;
    font-size: 1.5rem !important;
  }
}

@media (width >= 600px) {
  .home-container .content-container .hero-container h1 span:last-child {
    padding-top: 1.5rem;
    font-size: 3rem !important;
  }
}

@media (width >= 900px) {
  .home-container .content-container .hero-container h1 span:last-child {
    padding-top: 1.5rem;
    font-size: 3rem !important;
  }
}

@media (width >= 1200px) {
  .home-container .content-container .hero-container h1 span:last-child {
    padding-top: 2.5rem;
    font-size: 4rem !important;
  }
}

.home-container .content-container .hero-container h4, .home-container .content-container .hero-container h6 {
  opacity: 0;
  animation: .8s .8s forwards anim-headings;
}

@keyframes anim-headings {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (width <= 599px) {
  .home-container .content-container .hero-container h4, .home-container .content-container .hero-container h6 {
    margin: 16px 0;
  }
}

@media (width >= 600px) {
  .home-container .content-container .hero-container h6 {
    margin: 16px 0;
  }
}

.home-container .content-container .cta-container {
  text-align: center;
  margin: 32px 0;
}

.home-container .content-container .cta-container .cta-btn {
  opacity: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  animation: .8s 1.2s forwards anim-show;
}

@keyframes anim-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.about-container {
  position: relative;
  overflow: hidden;
}

.about-container .content-container {
  padding-top: 5rem;
}

.about-container .content-container .about-content-container {
  text-align: center;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.about-container .content-container .about-content-container input {
  appearance: none;
}

.about-container .content-container .about-content-container .radio-labels {
  margin: 0 auto;
}

.about-container .content-container .about-content-container .slider {
  margin: 0 auto;
  position: relative;
}

.about-container .content-container .about-content-container .slider .slide {
  display: none;
}

@media (width <= 599px) {
  .about-container .content-container .about-content-container .slider .slide .firm-info {
    margin: 16px 8px;
    text-align: center !important;
  }
}

@media (width >= 600px) {
  .about-container .content-container .about-content-container .slider .slide .firm-info {
    margin: 16px;
    text-align: left !important;
  }
}

.about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals {
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 16px 0;
  display: flex;
}

@media (width <= 599px) {
  .about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals {
    flex-direction: column;
  }
}

.about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 220px;
  display: flex;
  position: relative;
}

.about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical *, .about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical:before, .about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical:after {
  transition: all .3s linear;
}

.about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical:before, .about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical:after {
  content: "";
  border: 2px solid #a6a6a6;
  border: 2px solid var(--shade-1);
  border-color: #0000 #a6a6a6;
  border-color: transparent var(--shade-1);
  border-radius: 50%;
  position: absolute;
  inset: 0;
}

.about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical .caption {
  background-color: #222;
  background-color: var(--clr-fg-1);
  border: 1px solid #a6a6a6;
  border: 1px solid var(--shade-1);
  border-radius: 50%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  display: flex;
  position: absolute;
}

.about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical .caption p {
  color: var(--clr-bg-1);
}

.about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical .know-more {
  background-color: #222;
  background-color: var(--clr-fg-1);
  opacity: 0;
  border-radius: 50%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  display: flex;
  position: absolute;
}

.about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical .know-more a {
  border: 1px solid #fff;
  border: 1px solid var(--clr-bg-1);
  background: #fff;
  background: var(--clr-bg-1);
  color: var(--clr-fg-1);
  z-index: 2;
  z-index: var(--z-index-2);
  -webkit-text-decoration: none;
  text-decoration: none;
}

.about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical:hover .know-more {
  opacity: 1;
}

.about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical:hover:before, .about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical:hover:before, .about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical:hover:after, .about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical:hover:after {
  border-width: 5px;
}

.about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical:hover:before, .about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical:hover:before {
  transform: rotate(45deg);
}

.about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical:hover:after, .about-container .content-container .about-content-container .slider .slide .firm-lobs .verticals .vertical:hover:after {
  transform: rotate(-45deg);
}

@media (width <= 599px) {
  .about-container .content-container .about-content-container .slider .slide .team-info {
    margin: 16px 0;
    text-align: center !important;
  }

  .about-container .content-container .about-content-container .slider .slide .team-info .mt-8 {
    margin-top: 8px;
  }

  .about-container .content-container .about-content-container .slider .slide .team-info p {
    padding: 0 16px;
  }
}

@media (width >= 600px) {
  .about-container .content-container .about-content-container .slider .slide .team-info {
    margin: 16px 0;
    text-align: center !important;
  }

  .about-container .content-container .about-content-container .slider .slide .team-info .mt-8 {
    margin-top: 8px;
  }
}

.about-container .content-container .about-content-container .slider .slide .profile-akash {
  gap: 8px;
  display: flex;
}

@media (width <= 599px) {
  .about-container .content-container .about-content-container .slider .slide .profile-akash {
    flex-direction: column;
    align-items: center;
    margin: 24px 8px;
  }
}

@media (width >= 600px) {
  .about-container .content-container .about-content-container .slider .slide .profile-akash {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile {
  background: linear-gradient(#333, #222);
  background: var(--profile-card-gradient-color);
  text-align: center;
  border-radius: 1rem 1rem 11rem 11rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 280px;
  margin: 24px 8px;
  padding: 2.5rem 1.5rem 3.5rem;
  display: flex;
  position: relative;
  box-shadow: 0 8px 32px #174f4d26;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-img-border {
  background-color: #222;
  background-color: var(--profile-card-border-color);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 170px;
  display: flex;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-img-background {
  background-color: #fff;
  background-color: var(--clr-bg-1);
  border-radius: 50%;
  justify-content: center;
  align-items: flex-end;
  width: 150px;
  height: 150px;
  display: flex;
  overflow: hidden;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-img {
  background-image: url("akash.1b4fbb70.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 150px;
  height: 150px;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-name, .about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-profession {
  color: var(--clr-bg-1);
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-name {
  margin: 16px 0 8px;
  font-size: 1.2rem;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-profession {
  font-size: .833rem;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info {
  background-color: #fff;
  background-color: var(--clr-bg-1);
  clip-path: circle(16px at 250px 40px);
  border-radius: 1rem 1rem 11rem 11rem;
  width: 100%;
  padding: 2.5rem 1.5rem 3.3rem;
  transition: clip-path .4s ease-in-out;
  position: absolute;
  top: -1px;
  left: 0;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-icon {
  position: absolute;
  top: 28px;
  left: 238px;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-icon svg {
  fill: var(--clr-fg-1);
  width: 24px !important;
  height: 24px !important;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-border {
  background-color: #fff;
  background-color: var(--clr-bg-1);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 115px;
  margin: 0 auto 1rem;
  transition: transform .8s;
  display: flex;
  transform: translateX(-3rem);
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-border .profile-info-background {
  background-color: #fff;
  background-color: var(--clr-bg-1);
  border-radius: 50%;
  justify-content: center;
  align-items: flex-end;
  width: 115px;
  height: 115px;
  display: flex;
  overflow: hidden;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-border .profile-info-background .profile-info-img {
  background-image: url("akash-sketch.f17db4d0.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 115px;
  height: 115px;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-data {
  transition: transform 1s;
  transform: translateX(-4rem);
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-data .profile-info-name, .about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-data .profile-info-profession, .about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-data .profile-info-location {
  color: var(--clr-fg-1);
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-data .profile-info-name {
  margin: 8px;
  font-size: 1.2rem;
  font-weight: bold;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-data .profile-info-profession, .about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-data .profile-info-location {
  margin: 8px;
  font-size: .833rem;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-social {
  justify-content: center;
  margin: 4px;
  transition: transform 1.2s;
  display: flex;
  transform: translateX(-5rem);
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-social a {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  transition: transform .3s;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-social a svg {
  fill: var(--clr-fg-1);
  width: 24px;
  height: 24px;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info .profile-info-social a:hover {
  transform: translateY(-.25rem);
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info:hover {
  clip-path: circle(75%);
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info:hover .profile-info-border, .about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info:hover .profile-info-data, .about-container .content-container .about-content-container .slider .slide .profile-akash .profile .profile-info:hover .profile-info-social {
  transform: translateX(0);
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline {
  place-items: center;
  max-width: 430px;
  margin: 24px 8px;
  display: grid;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion {
  grid-row-gap: .75rem;
  background-color: #fff;
  background-color: var(--clr-bg-1);
  border-radius: .5rem;
  row-gap: .75rem;
  display: grid;
  box-shadow: 0 12px 32px #3333331a;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item {
  text-align: left;
  border-radius: .25rem;
  transition: all .25s;
  position: relative;
  box-shadow: 0 2px 6px #2626261a;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item:after {
  content: "";
  border-radius: .25rem 0 0 .25rem;
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item:first-child {
  background-color: #f0f0ff;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item:first-child:after {
  background-color: #8a8aff;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item:nth-child(2) {
  background-color: #fff0f3;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item:nth-child(2):after {
  background-color: #ff8aa1;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item:nth-child(3) {
  background-color: #f0faff;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item:nth-child(3):after {
  background-color: #8ad8ff;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item .accordion-header {
  -moz-column-gap: .5rem;
  cursor: pointer;
  align-items: center;
  column-gap: .5rem;
  padding: 1.25rem 1.25rem 1.25rem 1rem;
  display: flex;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item .accordion-header svg {
  color: var(--clr-text-2);
  width: 24px;
  height: 24px;
  font-size: 1.5rem;
  transition: all .3s;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item .accordion-header .accordion-header-description .title-sub {
  color: var(--clr-fg-1);
  margin-top: .15rem;
  font-size: .833rem;
  transition: all .2s;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item .accordion-header .accordion-header-description .title {
  color: var(--clr-fg-1);
  margin-top: .15rem;
  font-size: 1rem;
  transition: all .2s;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item .accordion-content {
  height: 0;
  transition: all .25s;
  overflow: hidden;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item .accordion-content .accordion-content-description {
  color: var(--clr-text-2);
  padding: 0 1.25rem 1.25rem 3rem;
  font-weight: 400;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion .accordion-item .accordion-content .accordion-content-description p {
  color: var(--clr-fg-1);
  font-size: .833rem;
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion-open svg {
  transform: rotate(45deg);
}

.about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion-open h5, .about-container .content-container .about-content-container .slider .slide .profile-akash .profile-timeline .accordion-open h3 {
  font-weight: bold;
}

.about-container .content-container .about-content-container input:first-child:checked ~ .radio-labels label:first-child, .about-container .content-container .about-content-container input:nth-child(2):checked ~ .radio-labels label:nth-child(2) {
  border: 1px solid #222;
  border: 1px solid var(--clr-fg-1);
  background: #222;
  background: var(--clr-fg-1);
  color: var(--clr-bg-1);
}

.about-container .content-container .about-content-container input:first-child:checked ~ .slider .slide:first-child, .about-container .content-container .about-content-container input:nth-child(2):checked ~ .slider .slide:nth-child(2) {
  display: block;
}

.products-container {
  width: 100%;
  height: 600vh;
  position: relative;
}

.products-container .content-container {
  width: 100%;
  height: 100%;
  padding-top: 5rem;
}

.products-container .content-container .products-info-container {
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 12vh;
  overflow: hidden;
}

.products-container .content-container .products-info-container .products-info .mt-16 {
  margin-top: 16px;
}

.products-container .content-container .products-info-container .products-info p {
  padding: 0 16px;
}

.products-container .content-container .products-info-container .word-cloud {
  background-image: url("wordcloud.646734cf.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

@media (width <= 599px) {
  .products-container .content-container .products-info-container .word-cloud {
    height: 25%;
  }
}

@media (width >= 600px) {
  .products-container .content-container .products-info-container .word-cloud {
    height: 55%;
  }
}

.products-container .content-container .product {
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 25vh;
  overflow: hidden;
}

.products-container .content-container .product .product-card {
  border: 1px solid #a6a6a6;
  border: 1px solid var(--shade-1);
  background-color: #fff;
  background-color: var(--clr-bg-1);
  border-radius: 5px;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 8px;
  display: flex;
}

@media (width <= 599px) {
  .products-container .content-container .product .product-card {
    width: 80%;
    height: 70%;
  }
}

@media (width >= 600px) {
  .products-container .content-container .product .product-card {
    width: 70%;
    height: 60%;
  }
}

@media (width >= 900px) {
  .products-container .content-container .product .product-card {
    width: 65%;
    height: 50%;
  }
}

.products-container .content-container .product .product-card .row {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.products-container .content-container .product .product-card .row h5 {
  position: relative;
}

.products-container .content-container .product .product-card .row h5:after {
  content: "";
  background-color: #222;
  background-color: var(--clr-fg-1);
  width: 30%;
  height: 3px;
  margin: 10px auto auto;
  display: block;
}

.products-container .content-container .product .product-card .row p {
  padding: 4px 0;
}

@media (width <= 599px) {
  .products-container .content-container .product .product-card .row p {
    font-size: .833rem;
  }
}

@media (width >= 600px) {
  .products-container .content-container .product .product-card .row p {
    font-size: .833rem;
  }
}

@media (width >= 900px) {
  .products-container .content-container .product .product-card .row p {
    font-size: 1rem;
  }
}

.products-container .content-container .product .product-card .row:nth-child(odd), .products-container .content-container .product .product-card .row:nth-child(2n) {
  padding: 8px 0;
}

.products-container .content-container .product .product-card .row:first-child {
  padding: 16px 0 !important;
}

.services-container {
  position: relative;
  overflow: hidden;
}

.services-container .content-container {
  padding-top: 5rem;
}

.services-container .content-container .services-content-container {
  justify-content: center;
  align-items: center;
  gap: 32px;
  display: flex;
}

@media (width <= 599px) {
  .services-container .content-container .services-content-container {
    flex-direction: column;
  }
}

@media (width >= 600px) {
  .services-container .content-container .services-content-container {
    flex-direction: column;
  }
}

@media (width >= 900px) {
  .services-container .content-container .services-content-container {
    flex-direction: row;
  }
}

.services-container .content-container .services-content-container .services-info-container {
  flex: 1;
  padding: 0 16px;
}

.services-container .content-container .services-content-container .services-info-container a {
  color: var(--clr-utility-green-1);
  border-bottom: 1px solid #228b22;
  border-bottom: 1px solid var(--clr-utility-green-1);
  padding-bottom: 1px;
}

.services-container .content-container .services-content-container .services-grid-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
}

.services-container .content-container .services-content-container .services-grid-container .services-grid-list {
  --amount: 3;
  --counter: 1;
  grid-template-columns: repeat(3, 1fr 2fr) 1fr;
  grid-template-columns: repeat(var(--amount), 1fr 2fr) 1fr;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  position: relative;
}

@media (width <= 599px) {
  .services-container .content-container .services-content-container .services-grid-container .services-grid-list {
    grid-gap: 1.5rem 3rem;
  }
}

@media (width >= 600px) {
  .services-container .content-container .services-content-container .services-grid-container .services-grid-list {
    grid-gap: 2rem 4rem;
  }
}

.services-container .content-container .services-content-container .services-grid-container .services-grid-item {
  grid-column: 1 / span 3;
  padding-bottom: 90%;
  position: relative;
}

.services-container .content-container .services-content-container .services-grid-container .services-grid-content {
  color: var(--clr-bg-1);
  background-color: #222;
  background-color: var(--clr-fg-1);
  clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.services-container .content-container .services-content-container .services-grid-container .services-grid-content p {
  text-align: center;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
}

@media (width <= 599px) {
  .services-container .content-container .services-content-container .services-grid-container .services-grid-content p {
    font-size: .694rem;
  }
}

@media (width >= 600px) {
  .services-container .content-container .services-content-container .services-grid-container .services-grid-content p {
    font-size: .833rem;
  }
}

.services-container .content-container .services-content-container .services-grid-container .services-grid-heading p {
  text-align: center;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
}

@media (width <= 599px) {
  .services-container .content-container .services-content-container .services-grid-container .services-grid-heading p {
    font-size: .833rem;
  }
}

@media (width >= 600px) {
  .services-container .content-container .services-content-container .services-grid-container .services-grid-heading p {
    font-size: 1rem;
  }
}

.services-container .content-container .services-content-container .services-grid-container .services-grid-item {
  grid-row: calc(1 + 1) / span 2;
  grid-row: calc(var(--counter)  + var(--counter)) / span 2;
  --amount: 3;
  --counter: 1;
}

.services-container .content-container .services-content-container .services-grid-container .services-grid-item:nth-of-type(3n+1) {
  grid-column: 1 / span 3;
}

.services-container .content-container .services-content-container .services-grid-container .services-grid-item:nth-of-type(3n+2) {
  grid-column: 3 / span 3;
  grid-row: calc(var(--counter)  + var(--counter)  - 1) / span 2;
}

.services-container .content-container .services-content-container .services-grid-container .services-grid-item:nth-of-type(3n+3) {
  grid-column: 5 / span 3;
}

.services-container .content-container .services-content-container .services-grid-container .services-grid-item:nth-of-type(n+4) {
  --counter: 2;
}

.services-container .content-container .services-content-container .services-grid-container .services-grid-item:nth-of-type(n+7) {
  --counter: 3;
}

.services-container .content-container .services-content-container .services-grid-container .services-grid-heading {
  display: block;
}

.services-container .content-container .services-content-container .services-grid-container .services-grid-heading > * {
  background-color: #fff !important;
  background-color: var(--clr-bg-1) !important;
  color: var(--clr-fg-1) !important;
}

.ecommerce-container {
  position: relative;
  overflow: hidden;
}

.ecommerce-container .content-container {
  padding-top: 5rem;
}

.ecommerce-container .content-container .ecommerce-content-container .ecommerce-card {
  background-color: #fff;
  background-color: var(--clr-bg-1);
  color: var(--clr-fg-1);
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 8px;
  display: flex;
}

.ecommerce-container .content-container .ecommerce-content-container .ecommerce-card a {
  color: var(--clr-utility-green-1);
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

@media (width <= 599px) {
  .ecommerce-container .content-container .ecommerce-content-container .ecommerce-card {
    width: 80%;
  }
}

@media (width >= 600px) {
  .ecommerce-container .content-container .ecommerce-content-container .ecommerce-card {
    width: 80%;
    padding: 8px;
  }
}

.ecommerce-container .content-container .ecommerce-content-container .ecommerce-card .row {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.ecommerce-container .content-container .ecommerce-content-container .ecommerce-card .row h5 {
  position: relative;
}

.ecommerce-container .content-container .ecommerce-content-container .ecommerce-card .row h5:after {
  content: "";
  background-color: #222;
  background-color: var(--clr-fg-1);
  width: 30%;
  height: 3px;
  margin: 10px auto auto;
  display: block;
}

.ecommerce-container .content-container .ecommerce-content-container .ecommerce-card .row p {
  padding: 4px 0;
  font-size: .833rem;
}

.ecommerce-container .content-container .ecommerce-content-container .ecommerce-card .row:nth-child(odd), .ecommerce-container .content-container .ecommerce-content-container .ecommerce-card .row:nth-child(2n) {
  padding: 8px 0;
}

.ecommerce-container .content-container .ecommerce-content-container .ecommerce-card .row:first-child {
  padding: 16px 0 !important;
}

.contact-container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.contact-container .contact-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
}

@media (width <= 599px) {
  .contact-container .contact-canvas {
    display: none;
  }
}

.contact-container .content-container {
  width: 100%;
  height: 100%;
  padding-top: 5rem;
}

.contact-container .content-container .contact-content-container {
  width: 100%;
}

.contact-container .content-container .contact-content-container .contact-info {
  width: 100%;
  position: absolute;
}

@media (width <= 599px) {
  .contact-container .content-container .contact-content-container .contact-info {
    text-align: center;
    flex-direction: column;
    gap: 32px;
    display: flex;
  }
}

@media (width >= 600px) {
  .contact-container .content-container .contact-content-container .contact-info {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    display: flex;
    bottom: 8vh;
  }
}

.contact-container .content-container .contact-content-container .contact-info .firm-location h6, .contact-container .content-container .contact-content-container .contact-info .contact-address h6 {
  margin-bottom: 8px;
  font-size: .833rem;
}

.contact-container .content-container .contact-content-container .contact-footer {
  border-top: .5px solid #222;
  border-top: .5px solid var(--clr-fg-1);
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7vh;
  padding: 8px 0;
  display: flex;
  position: absolute;
  bottom: 0;
}

.contact-container .content-container .contact-content-container .contact-footer p {
  font-size: .833rem;
}
/*# sourceMappingURL=index.65c9c326.css.map */
