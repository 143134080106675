@use './css-variables';
@use './typography' as *;
@use './responsive' as *;

.nav-menu-container {
    background-color: var(--clr-fg-1);
    position: absolute;
    height: 99%;
    transition: all 1s ease;
    transform: translateX(-100vw);
    z-index: var(--z-index-998);
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @include for-phone-only {
        width: 100%;
    }

    @include for-tablet-portrait-up {
        width: 50%;
    }

    ul {
        position: sticky;
        top: 30%;
        width: 100%;
        //border: 1px solid red;
        text-align: left;
        padding: 0 32px;

        li {
            list-style: none;
            padding: 4px 0;
            //border: 1px solid green;

            a {
                text-decoration: none;
                color: var(--clr-bg-1);
                @include fs-heading-h3;
                // transition: transform 0.5s ease;
                // transform: translateX(-8px);
                //border: 1px solid yellow;
                text-align: center;

                &:hover {
                    color: var(--shade-1);
                }
            }
        }
    }

    .contact-info {
        position: sticky;
        top: 85%;
        width: 100%;
        background-color: var(--shade-1);
        padding: 8px 16px;

        p, h6 {
            color: var(--clr-fg-1);
            text-align: right;
            padding: 0 16px;
        }

        p {
            @include fs-body-small-1;
        }
    }
}