:root {
    --max-width: 1240px;
    //--max-width: 1440px;

    --clr-bg-1: #FFFFFF;
    --clr-fg-1: #222222;
    --clr-utility-red-1: #FF0000;
    --clr-utility-green-1: #228B22;

    --z-index-1000: 1000;
    --z-index-999: 999;
    --z-index-998: 998;
    --z-index-3: 3;
    --z-index-2: 2;
    --z-index-1: 1;

    --shade-1: #A6A6A6;

    --profile-card-gradient-color: linear-gradient(180deg, #333, #222);
    --profile-card-border-color: #222;
}