@use './css-variables';
@use './typography' as *;
@use './responsive' as *;


.preloader-container {

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--clr-fg-1);

    z-index: var(--z-index-1000);
    
    .loader {
        width: 100%;
        height: 15px;
        text-align: center;
        position: fixed;

        .dot {
            position: relative;
            width: 15px;
            height: 15px;
            margin: 0 2px;
            display: inline-block;
        }
          
        .dot:first-child:before {
            animation-delay: 0ms;
        }
          
        .dot:first-child:after {
            animation-delay: 0ms;
        }
          
        .dot:last-child:before {
            animation-delay: 200ms;
        }
          
        .dot:last-child:after {
            animation-delay: 200ms;
        }
          
        .dot:before{
            content: "";
            position: absolute;
            left: 0;
            width: 15px;
            height: 15px;
            animation-name: animDot;
            animation-duration: 900ms;
            animation-timing-function: cubic-bezier(.75,0,.20,1);
            animation-iteration-count: infinite;
            animation-delay: 100ms;
            background: var(--clr-bg-1);
            border-radius: 50%;  
        }
          
        .dot:after {
            content: "";
            position: absolute;
            z-index: -1;
            background: var(--clr-bg-1);
            box-shadow: 0px 0px 1px var(--clr-bg-1);
            opacity: .20;
            width: 100%;
            height: 3px;
            left: 0;
            bottom: -2px;
            border-radius: 50%;
            animation-name: animDotShadow;
            animation-duration: 900ms;
            animation-timing-function: cubic-bezier(.75,0,.20,1);
            animation-iteration-count: infinite;
            animation-delay: 100ms;
        }
          
        @keyframes animDot {
            0% {
              top: 0px;
            }
            50% {
              top: -50px;
              transform: scale(1.1);
            }
            100% {
              top: 0;
            }
        }

        @keyframes animDotShadow {
            0% {
              transform: scaleX(1);
            }
            50% {
              opacity: 0;
              transform: scaleX(.6);
            }
            100% {
              transform: scaleX(1);
            }
        }
        
    } 
}