@use './css-variables';
@use './typography' as *;
@use './responsive' as *;

@mixin firm-info {

    @include for-phone-only {
        margin: 16px 8px;
        text-align: center !important;
    }
    @include for-tablet-portrait-up {
        margin: 16px 16px;
        text-align: left !important;
    }
}

@mixin firm-lobs {

    .verticals {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin: 16px 0;

        @include for-phone-only {
            flex-direction: column;
        }

        .vertical {
            width: 220px;
            height: 220px;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;

            position: relative;

            & *,
            &::before,
            &::after {
                transition: all 0.3s linear;
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                border-radius: 50%;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                border: 2px solid var(--shade-1);
                border-color: transparent var(--shade-1);
            }

            .caption {
                position: absolute;

                width: 200px;
                height: 200px;
                border-radius: 50%;
                background-color: var(--clr-fg-1);
                border: 1px solid var(--shade-1);

                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                p {
                    color: var(--clr-bg-1);
                }
            }

            .know-more {
                position: absolute;

                width: 200px;
                height: 200px;
                border-radius: 50%;
                background-color: var(--clr-fg-1);
        
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
        
                opacity: 0;

                a { 
                    border: 1px solid var(--clr-bg-1);
                    background: var(--clr-bg-1);
                    color: var(--clr-fg-1);
                    z-index: var(--z-index-2);
                    text-decoration: none;
                }
            }

            &:hover {
                .know-more {
                    opacity: 1;
                }
            }

            &:hover:before,
            &:hover:before,
            &:hover:after,
            &:hover:after {
                border-width: 5px;
            }

            &:hover:before,
            &:hover:before {
                transform: rotate(45deg);
            }

            &:hover:after,
            &:hover:after {
                transform: rotate(-45deg);
            }
        }
    }
}

@mixin team-info {
    @include for-phone-only {
        margin: 16px 0px;
        text-align: center !important;
        .mt-8 {
            margin-top: 8px;
        }
        p {
            padding: 0 16px;
        }
    }
    @include for-tablet-portrait-up {
        margin: 16px 0;
        text-align: center !important;
        .mt-8 {
            margin-top: 8px;
        }
    }
}

@mixin profile-akash {
    display: flex;
    gap: 8px;
    
    @include for-phone-only {
        margin: 24px 8px;
        flex-direction: column;
        align-items: center;
    }

    @include for-tablet-portrait-up {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }

    .profile {
        margin: 24px 8px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        position: relative;

        width: 280px;
        background: var(--profile-card-gradient-color);
        border-radius: 1rem 1rem 11rem 11rem;
        padding: 2.5rem 1.5rem 3.5rem;
        text-align: center;
        box-shadow: 0 8px 32px hsla(178, 55%, 20%, .15);

        .profile-img-border {
            width: 170px;
            height: 170px;
            background-color: var(--profile-card-border-color);
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        .profile-img-background {
            width: 150px;
            height: 150px;
            background-color: var(--clr-bg-1);
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }

        .profile-img {
            width: 150px;
            height: 150px;
            background-image: url('../assets/images/akash.png');
            background-repeat: no-repeat;
            background-size: cover;
        }

        .profile-name,
        .profile-profession {
            color: var(--clr-bg-1);
        }

        .profile-name {
            @include fs-heading-h6;
            margin: 16px 0 8px 0;
        }

        .profile-profession {
            @include fs-body-small-1;
        }

        .profile-info {
            position: absolute;
            
            top: -1px;
            left: 0;
            width: 100%;
            background-color: var(--clr-bg-1);
            padding: 2.5rem 1.5rem 3.3rem;
            border-radius: 1rem 1rem 11rem 11rem;
            clip-path: circle(16px at 250px 40px);
            transition: clip-path .4s ease-in-out;

            .profile-info-icon {
                position: absolute;
                top: 28px;
                left: 238px;
                svg {
                    width: 24px !important;
                    height: 24px !important;
                    fill: var(--clr-fg-1);
                }
            }

            .profile-info-border {
                width: 115px;
                height: 115px;
                background-color: var(--clr-bg-1);
                border-radius: 50%;
                margin: 0 auto 1rem;
                transform: translateX(-3rem);
                transition: transform .8s;

                display: flex;
                align-items: center;
                justify-content: center;

                .profile-info-background {
                    width: 115px;
                    height: 115px;
                    background-color: var(--clr-bg-1);
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;

                    .profile-info-img {
                        width: 115px;
                        height: 115px;
                        background-image: url('../assets/images/akash-sketch.png');
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }
            }

            .profile-info-data {
                transform: translateX(-4rem);
                transition: transform 1s;

                .profile-info-name,
                .profile-info-profession,
                .profile-info-location {
                    color: var(--clr-fg-1);
                }

                .profile-info-name {
                    font-weight: bold;
                    @include fs-heading-h6;
                    margin: 8px;
                }
                .profile-info-profession,
                .profile-info-location {
                    @include fs-body-small-1;
                    margin: 8px;
                }
            }

            .profile-info-social {
                margin: 4px;
                display: flex;
                justify-content: center;
                transform: translateX(-5rem);
                transition: transform 1.2s;

                a {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    transition: transform .3s;

                    svg {
                        width: 24px;
                        height: 24px;
                        fill: var(--clr-fg-1);
                    }

                    &:hover{
                        transform: translateY(-.25rem);
                    }
                }
            }

            &:hover{
                clip-path: circle(75%);
            }

            &:hover .profile-info-border,
            &:hover .profile-info-data,
            &:hover .profile-info-social {
                transform: translateX(0);
            }
        }
    }

    .profile-timeline {
        margin: 24px 8px;

        display: grid;
        place-items: center;
        max-width: 430px;

        .accordion {
            display: grid;
            row-gap: .75rem;
            //padding: 2rem 1rem;
            background-color: var(--clr-bg-1);
            border-radius: .5rem;
            box-shadow: 0 12px 32px hsla(0, 0%, 20%, 0.1);

            .accordion-item {
                box-shadow: 0 2px 6px hsla(0, 0%, 15%, 0.1);
                border-radius: .25rem;
                position: relative;
                transition: all .25s ease;
                text-align: left;

                &::after {
                    content: '';
                    width: 5px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: .25rem 0 0 .25rem;
                }

                &:nth-child(1){
                    background-color: hsl(240, 100, 97);
        
                    &::after{
                        background-color: hsl(240, 100, 77);
                    }
                }

                &:nth-child(2){
                    background-color: hsl(348, 100, 97);
        
                    &::after{
                        background-color: hsl(348, 100, 77);
                    }
                }

                &:nth-child(3){
                    background-color: hsl(200, 100, 97);
        
                    &::after{
                        background-color: hsl(200, 100, 77);
                    }
                }

                .accordion-header {
                    display: flex;
                    align-items: center;
                    column-gap: .5rem;
                    padding: 1.25rem 1.25rem 1.25rem 1rem;
                    cursor: pointer;

                    svg {
                        font-size: 1.5rem;
                        color: var(--clr-text-2);
                        transition: .3s;
                        width: 24px;
                        height: 24px;
                    }

                    .accordion-header-description {
                        .title-sub {
                            color: var(--clr-fg-1);
                            margin-top: .15rem;
                            transition: .2s;
                            @include fs-body-small-1;
                        }
                        .title {
                            color: var(--clr-fg-1);
                            margin-top: .15rem;
                            transition: .2s;
                            @include fs-body;
                        }
                    }
                }
                .accordion-content {
                    overflow: hidden;
                    height: 0;
                    transition: all .25s ease;


                    .accordion-content-description {
                        padding: 0 1.25rem 1.25rem 3rem;
                        color: var(--clr-text-2);
                        font-weight: 400;

                        p {
                            color: var(--clr-fg-1);
                            @include fs-body-small-1;
                        }
                    }
                }
            }

            &-open{
                svg{
                    transform: rotate(45deg);
                }
                h5 {
                    font-weight: bold;
                }
                h3{
                    font-weight: bold;
                }
            }
        }
    }
}

.about-container {
    position: relative;
    overflow: hidden;

    .content-container { 
        padding-top: 5rem;

        .about-content-container {
            text-align: center;

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            input {
                appearance: none;
            }
    
            .radio-labels {
                margin: 0 auto;
                //background-color: green;
            }

            .slider {
                position: relative;
                margin: 0 auto;
                .slide {
                    display: none;

                    .firm-info {
                        @include firm-info;
                    }

                    .firm-lobs {
                        @include firm-lobs;
                    }
    
                    .team-info {
                        @include team-info;
                    }
    
                    .profile-akash {
                        //margin-top: 5vh;
                        @include profile-akash;
                    }
                }
            }

            input:nth-child(1):checked ~ .radio-labels label:nth-child(1),
            input:nth-child(2):checked ~ .radio-labels label:nth-child(2) {
                border: 1px solid var(--clr-fg-1);
                background: var(--clr-fg-1);
                color: var(--clr-bg-1);
            }

            input:nth-child(1):checked ~ .slider .slide:nth-child(1),
            input:nth-child(2):checked ~ .slider .slide:nth-child(2) {
                display: block;
            }
        }

    }

}