@use "./css-variables";
@use "./typography" as *;
@use "./responsive" as *;

.ecommerce-container {
  position: relative;
  overflow: hidden;

  .content-container {
    padding-top: 5rem;

    .ecommerce-content-container {
      .ecommerce-card {
        margin: auto;
        background-color: var(--clr-bg-1);
        color: var(--clr-fg-1);

        a {
          color: var(--clr-utility-green-1);
          text-decoration: underline;
        }

        @include for-phone-only {
          width: 80%;
        }

        @include for-tablet-portrait-up {
          width: 80%;
          padding: 8px 8px;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;

        padding: 8px;

        .row {
          text-align: center;
          width: 100%;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          h5 {
            position: relative;

            &::after {
              content: "";
              width: 30%;
              display: block;
              height: 3px;
              background-color: var(--clr-fg-1);
              margin: auto;
              margin-top: 10px;
            }
          }

          p {
            padding: 4px 0;
            @include fs-body-small-1;
          }

          &:nth-child(odd) {
            //background-color: var(--clr-bg-2);
            padding: 8px 0;
          }

          &:nth-child(even) {
            //background-color: var(--clr-utility-F0EBE3);
            padding: 8px 0;
          }

          &:nth-child(1) {
            //background-color: var(--clr-bg-2);
            padding: 16px 0 !important;
          }
        }
      }
    }
  }
}
